<template>
  <div class="popup">
    <div class="title3 mb-8">{{ $t('UploadCV.upload_your_cv') }}</div>
    <div class="text2">{{ $t('UploadCV.the_german_employer_needs') }}</div>
    <div class="space-between">
      <div>
        <file-uploader
          fileName="cv"
          :url="backendUrl"
          path="curriculum-vitaes"
          :value="cv"
          accept=".doc,.docx,.pdf"
          :fileSize="1e7"
          message="Click here to add a CV"
          class="mt-10"
          @progress="uploadProgress = $event"
          @success="handleUploaded"
          @error="handleUploadError"
          @delete="handleFileDelete"
        />
        <a-progress v-if="uploadProgress" :percent="uploadProgress" :show-info="false" />
      </div>
      <ui-button :disabled="!cv" @click="closeForce">{{ $t('UploadCV.sent_cv_to_the_employer') }}</ui-button>
    </div>
    <div class="close" @click="close"><img src="@/assets/images/close.svg" /></div>
  </div>
</template>

<script>
import { paramsForServer } from 'feathers-hooks-common';
import UiButton from '../ui/UiButton';
import FileUploader from '../FileUploader';
import config from '../../config';
import mapFields from '../../utils/mapFields';

export default {
  components: { FileUploader, UiButton },
  props: {
    value: {
      require: false,
      type: Object
    }
  },
  data() {
    return {
      backendUrl: config.backendUrl,
      uploadProgress: 0,
      cv: undefined
    };
  },
  computed: {
    ...mapFields()
  },
  async created() {
    const { data } = await this.$store.dispatch('curriculum-vitaes/find', {
      query: { createdBy: this.userId }
    });

    if (data.length) {
      // eslint-disable-next-line prefer-destructuring
      this.cv = data[0];
    }
  },
  methods: {
    handleUploaded([cv]) {
      this.cv = cv;
    },
    handleUploadError(error) {
      const message = error?.response?.data?.message || error;

      if (message === 'Validation error') {
        this.$notify.error('Validation error. Only one CV for person.');
      } else {
        this.$notify.error(message);
      }
    },
    async handleFileDelete() {
      await this.$store.dispatch('curriculum-vitaes/remove', [this.cv.id, paramsForServer({ disableParanoid: true })]);
      this.cv = undefined;
    },
    close() {
      this.$emit('close');
    },
    closeForce() {
      window.localStorage.setItem('UploadCV', 1);
      this.$emit('close');
    }
  }
};
</script>

<style>
.popup {
  width: 328px;
  height: 370px;
  background: #ffffff;
  border-radius: 4px;
  padding: 32px 24px;

  display: flex;
  flex-direction: column;
  position: relative;
}

.close {
  position: absolute;
  top: 19px;
  right: 27px;
  cursor: pointer;
}

.space-between {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
</style>
