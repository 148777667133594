<template>
  <div class="review_day">
    <div class="day flex-center">
      <div class="center">{{ month }}<br />{{ day }}</div>
    </div>
    <div class="spacer"></div>
    <div class="time flex-center">
      <div>{{ dayName }}, {{ time }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'EventDay',
  props: {
    startDate: {
      type: String
    },
    durationInMinutes: {
      type: Number
    }
  },
  computed: {
    month() {
      return dayjs(this.startDate).format('MMM');
    },
    day() {
      return dayjs(this.startDate).format('D');
    },
    dayName() {
      return dayjs(this.startDate).format('dddd');
    },
    time() {
      return `${dayjs(this.startDate).format('hh - ')}
          ${dayjs(this.startDate)
            .add(this.durationInMinutes, 'minute')
            .format('hh a')}`;
    }
  }
};
</script>

<style lang="scss">
.review_day {
  margin: auto;
  margin-bottom: 32px;
  width: 185px;
  height: 48px;
  display: flex;
  color: #383838;

  .day {
    min-width: 48px;
    height: 48px;
    background: #258ffb;
    border-radius: 2px;
    color: #ffffff;
  }

  .spacer {
    min-width: 8px;
  }

  .time {
    font-size: 16px;
    white-space: nowrap;
  }
}
</style>
