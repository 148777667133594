<template>
  <div class="onboarding_content">
    <img class="onboarding_icon mb" src="@/assets/images/review_2x.png"><br>
    <div class="title3 mb-8">{{$t('review.underReview')}}</div>
    <div class="text3 mb">
      {{$t('review.beenAccepted1')}}<br>
      {{$t('review.beenAccepted2')}}
    </div>
  </div>
</template>

<script>
  // import EnableNotifications from '@/components/review/EnableNotifications';

  export default {
    name: 'ReviewStarted',
    props: {
      modal: {
        type: Object
      }
    },
    mounted() {
      // setTimeout(() => {
      //   this.modal.component = EnableNotifications;
      //   this.modal.title = '';
      //   this.modal.componentData = {};
      //   this.modal.visible = true;
      //   this.modal.callback = () => {
      //     this.modal.visible = false;
      //   }
      // }, 1000);
    }
  }
</script>

<style scoped>

</style>
