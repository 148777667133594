<template>
<div>
  <h2>{{$t('PaymentChoosing.paymentchoosing')}}</h2>
  <button @click="emulateCard">{{$t('PaymentChoosing.emulate_pay_by_card_eur')}}</button><br><br>
  <button @click="emulateWire">{{$t('PaymentChoosing.emulate_wire_transfer_eur')}}</button><br><br>
</div>
</template>

<script>
export default {
  name: 'PaymentChoosing',
  computed: {
    studentId() {
      return this.$store.state.auth?.user?.student?.id;
    }
  },
  methods: {
    $log(...args) {
      console.log(...args);
    },
    async emulateCard() {
      console.log('emulateCard');

      try {
        const { data } = await this.$store.dispatch('payments/find', {
          query: {
            studentId: this.studentId
          }
        });
        this.$log(data);

        if (data.length) {
          const payment = data[0];
          const patchResult = await this.$store.dispatch('payments/patch', [
            payment.id,
            {
              paidAmount: 430
            }
          ]);
          this.$log(patchResult);

          try {
            const response = await this.$store.dispatch('student-statuses/create', {
              status: 'payment_by_card',
              comment: '',
              studentId: this.studentId
            });
            this.$log(response);
          } catch (error) {
            this.$notify.parseFeathersErrors(error);
          }

          // this.$emit('reloadTab');
        }
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },

  },
}
</script>

<style scoped>

</style>
