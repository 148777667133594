<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/payment_2x.png"><br>
      <div class="title3 mb-8">{{$t('PaymentCompleted.payment_is_required')}}</div>
      <div class="text3 mb">
        {{$t('PaymentCompleted.click_the_following_button')}}
      </div>
    </div>
    <!-- <div class="footer"> -->
    <div class="fixed_footer container">
      <ui-button class="mb-16">{{$t('PaymentCompleted.i_ve_completed_payment')}}</ui-button>
      <ui-link class="link">{{$t('PaymentCompleted.return_to_the_payment')}}</ui-link>
    </div>
  </div>
</template>

<script>
  import UiButton from '../ui/UiButton';
  import UiLink from '../ui/UiLink';

  export default {
    name: 'PaymentCompleted',
    components: { UiLink, UiButton }
  }
</script>

<style scoped>

</style>
