<route>
{
  "name": "status",
  "meta": {
    "title": "Status"
  }
}
</route>

<template>
  <div class="center onboarding_flex">
    <ReviewSteps :steps="steps" />
    <component v-if="component" :is="component" :modal="modal" @status="renderStatus" />
    <ui-modal-view :config="modal" />
  </div>
</template>

<script>
import feathersClient from '@/plugins/feathersClient';
import ReviewSteps from '@/components/review/ReviewSteps';
import UiModalView from '@/components/ui/UiModalView';

import ReviewStarted from '@/components/review/ReviewStarted';
import ReviewRejected from '@/components/review/ReviewRejected';

import InterviewBooking from '@/components/interview/InterviewBooking';
import InterviewBooked from '@/components/interview/InterviewBooked';
import InterviewAttended from '@/components/interview/InterviewAttended';
import InterviewMissed from '@/components/interview/InterviewMissed';
import InterviewRejected from '@/components/interview/InterviewRejected';

import PaymentByCard from '@/components/payment/PaymentByCard';
import PaymentByWireTransfer from '@/components/payment/PaymentByWireTransfer';
import PaymentCompleted from '@/components/payment/PaymentCompleted';
import PaymentConfirming from '@/components/payment/PaymentConfirming';
import PaymentRejected from '@/components/payment/PaymentRejected';

import PaymentChoosing from '@/components/payment/PaymentChoosing';
import Introduction from '../../components/introduction/Introduction';
import mapFields from '../../utils/mapFields';

export default {
  name: 'status',
  components: {
    PaymentChoosing,
    Introduction,
    ReviewSteps,
    UiModalView
  },
  data() {
    return {
      steps: ['started', 'no-started', 'no-started', 'no-started'],
      component: false,
      modal: {
        visible: false,
        component: {},
        componentData: {}
      }
    };
  },
  computed: {
    ...mapFields({
      user: 'auth.user',
      userId: 'auth.user.id',
      studentId: 'auth.user.student.id'
    })
  },
  async mounted() {
    if (!this.studentId) {
      this.redirectToFilling();
    }

    let response;
    try {
      response = await this.$store.dispatch('student-statuses/find', {
        query: {
          $select: ['status'],
          $limit: 1,
          $sort: {
            createdAt: -1
          }
        }
      });
    } catch (error) {
      console.log('error', error);
      this.$notify.parseFeathersErrors(error);
    }

    const { data: statusArray } = response;
    let initialStatus;

    // Redirect from this page if has no status
    if (!statusArray.length) {
      this.redirectToFilling();
    } else {
      initialStatus = statusArray[0]?.status;
    }

    this.renderStatus(initialStatus);

    feathersClient.service('student-statuses').on('created', (data) => {
      console.log('Status getted!', data.status);
      const { userId, studentId, status } = data;

      if (studentId === this.studentId || userId === this.userId) {
        this.renderStatus(status);
      }
    });
  },
  methods: {
    redirectToFilling() {
      const hasStudent = Boolean(this.user?.student);
      const hasDetail = Boolean(this.user?.student?.detail);
      let targetPageName = 'basic-info';

      if (hasStudent) targetPageName = 'professional-details';
      if (hasDetail) targetPageName = 'personal-photo';

      this.$router.push({
        name: targetPageName
      });
    },
    renderStatus(status) {
      console.log('Student status:', status);
      switch (status) {
        case 'review_started':
          this.component = ReviewStarted;
          break;
        case 'review_rejected':
          this.component = ReviewRejected;
          this.steps = ['error', 'no-started', 'no-started', 'no-started'];
          break;

        case 'interview_booking':
          this.component = InterviewBooking;
          this.steps = ['done', 'started', 'no-started', 'no-started'];
          break;
        case 'interview_booked':
          this.component = InterviewBooked;
          this.steps = ['done', 'started', 'no-started', 'no-started'];
          break;
        case 'interview_attended':
          this.component = InterviewAttended;
          this.steps = ['done', 'started', 'no-started', 'no-started'];
          break;
        case 'interview_missed':
          this.component = InterviewMissed;
          this.steps = ['done', 'started', 'no-started', 'no-started'];
          break;
        case 'interview_rejected':
          this.component = InterviewRejected;
          this.steps = ['done', 'started', 'error', 'no-started'];
          break;

        // this.component = PaymentChoosing;
        // this.steps = ['done', 'done', 'started', 'no-started'];
        // break;
        case 'choosing_payment':
        case 'payment_by_card':
          this.component = PaymentByCard;
          this.steps = ['done', 'done', 'started', 'no-started'];
          break;
        case 'payment_by_wire_transfer':
          this.component = PaymentByWireTransfer;
          this.steps = ['done', 'done', 'started', 'no-started'];
          break;
        case 'payment_completed':
          this.component = PaymentCompleted;
          this.steps = ['done', 'done', 'started', 'no-started'];
          break;
        case 'payment_confirming':
          this.component = PaymentConfirming;
          this.steps = ['done', 'done', 'started', 'no-started'];
          break;
        case 'payment_rejected':
          this.component = PaymentRejected;
          this.steps = ['done', 'done', 'error', 'no-started'];
          break;

        case 'introduction':
          this.component = Introduction;
          this.steps = ['done', 'done', 'done', 'started'];
          break;

        default:
          console.log('unknown status!');
      }
    }
  }
};
</script>

<style lang="scss">
.onboarding_flex {
  display: flex;
  flex-direction: column;

  .onboarding_wrap {
    // flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .onboarding_content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 79px;
  }

  .onboarding_icon {
    height: 120px;
  }
  .onboarding_icon_sm {
    height: 82px;
  }

  .onboarding_footer {
    height: 100px;
    .link {
      margin-top: 20px;
    }
  }

  .fixed_footer {
    position: fixed;
    padding: 16px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 140px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  @media screen and (max-height: 740px) {
    .onboarding_content {
      padding-top: 52px;
    }

    .onboarding_icon {
      display: none;
    }
  }

  .mb-fixed-footer {
    margin-bottom: 120px;
  }
}
</style>
