<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/sorry_2x.png"><br>
      <div class="title3 mb-8">{{$t('PaymentRejected.sorry_but_we_couldn_t')}}</div>
      <div class="text3 mb">
        {{$t('PaymentRejected.unfortunately_we_couldn_t')}}
      </div>

      <div class="contacts">
        <div class="mb-8">{{$t('PaymentRejected.if_you_have_any_problems')}}</div>
        <div><img class="whatsapp" src="@/assets/images/whatsapp_2x.png"> +69 1314315</div>
      </div>

    </div>
    <div class="footer">
      <ui-link class="link">{{$t('PaymentRejected.try_to_make_payment_again')}}</ui-link>
    </div>
  </div>
</template>

<script>
    import UiLink from '../ui/UiLink';

    export default {
        name: 'PaymentRejected',
      components: { UiLink }
    }
</script>

<style scoped>
  .contacts {
    width: 60%;
  }
  .whatsapp {
    height: 24px;
    vertical-align: middle;
  }
</style>
