<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content mb-fixed-footer">
      <img class="onboarding_icon mb" src="@/assets/images/payment_2x.png" /><br />
      <div class="title3 mb-8">{{$t('PaymentByCard.payment_is_required')}}</div>
      <div class="text3 mb-32">
        {{$t('PaymentByCard.you_were_granted')}}<br>
        {{$t('PaymentByCard.to_proceed_you_need_to_pay')}}
      </div>
      <!--
      <div class="slider">
        <div class="slider-title mb" @click="toggle">
          <div class="text">{{$t('PaymentByCard.how_can_i_make_a_card')}}</div>
          <div class="arrow">
            <img v-if="isShow" src="@/assets/images/arrow-down.svg" />
            <img v-else src="@/assets/images/arrow-right.svg" />
          </div>
        </div>
        <SlideUpDown :active="isShow" class="gray_main">
          <p>1. Tap to “{{$t('PaymentByCard.proceed_to_payment')}}”</p>
          <p>2. Select the course you want to pay for</p>
          <p>3. Choose the full payment or instalment (specify which one)</p>
          <p>4. Enter student info and click "Continue to checkout"</p>
          <p>5. Click "Inecobank" payment option</p>
          <p>6. Enter card details and finish the payment</p>
          <p><strong>{{$t('PaymentByCard.don_t_forget_to_return_to')}}</strong></p>
          <p class="grey_active">{{$t('PaymentByCard.please_double_check_all')}}</p>
          <p class="grey_active">
            {{$t('PaymentByCard.the_payment_can_only_be')}}
          </p>
        </SlideUpDown>
      </div>
      -->
    </div>
    <div class="fixed_footer container">
      <a :href="paymentLink">
        <ui-button class="mb-16">{{$t('PaymentByCard.proceed_to_payment')}}</ui-button>
      </a>
      <ui-link class="link" @click="setPaymentByWire">{{$t('PaymentByCard.i_don_t_have_a_card')}}</ui-link>
    </div>
  </div>
</template>

<script>
import UiButton from '../ui/UiButton';
import UiLink from '../ui/UiLink';
// import SlideUpDown from '../SlideUpDown';
import PaymentCongratulations from './PaymentCongratulations';
import mapFields from '../../utils/mapFields';
// import config from '../../config';

export default {
  name: 'PaymentByCard',
  components: { UiLink, UiButton }, // ,SlideUpDown
  props: {
    modal: {
      type: Object
    }
  },
  data() {
    return {
      isShow: false
    };
  },
  computed: {
    arrow() {
      return '>';
      // retur this.isShow ? ''
    },
    ...mapFields({
      userId: 'auth.user.id',
      studentId: 'auth.user.student.id',
      email: 'auth.user.email'
    }),
    paymentLink() {
      return 'https://checkout.germanlanguage.ph/product/german-a1-online-course';
      // Don't remove. Need for next version of payment
      // const isTestMode = process.env.NODE_ENV !== 'production';
      // const query = `email=${this.email}${isTestMode ? '&isTestMode=true' : ''}`;

      // return encodeURI(`${config.checkoutUrl}/studyflow/order/?${query}`);
    }
  },
  methods: {
    toggle() {
      this.isShow = !this.isShow;
    },
    async setPaymentByWire() {
      await this.$store.dispatch('student-statuses/create', {
        status: 'payment_by_wire_transfer',
        studentId: this.studentId
      });
    }
  },
  async mounted() {
    const flag = window.localStorage.getItem('PaymentCongratulations');
    if (!flag) {
      window.localStorage.setItem('PaymentCongratulations', '1');
      this.modal.component = PaymentCongratulations;
      this.modal.title = '';
      this.modal.componentData = {};
      this.modal.visible = true;
      this.modal.callback = () => {
        this.modal.visible = false;
      };
    }
    // console.log(this.$store)
    // await this.$store.dispatch('payments/create', paramsForServer({
    //   paymentName: 'Full payment'
    // }));
  }
};
</script>

<style scoped lang="scss">
.slider {
  width: 100%;
  text-align: left;
}
.slider-title {
  font-size: 16px;
  color: #383838;
  display: flex;
  .text {
    flex-grow: 1;
  }
}
</style>
