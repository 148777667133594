<template>
  <div class="onboarding_content">
    <img class="onboarding_icon_sm mb" src="@/assets/images/sorry_2x.png"><br>
    <div class="title3 mb-8">{{$t('ReviewRejected.we_are_sorry')}}</div>
    <div class="text3 mb">
      {{$t('ReviewRejected.your_application_can_t_be')}}
    </div>
  </div>
</template>

<script>
    export default {
        name: 'ReviewRejected'
    }
</script>

<style scoped>

</style>
