<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/interview_2x.png" /><br />
      <div class="title3 mb-8">{{ $t('InterviewBooked.booking_an_interview') }}</div>
      <div class="text3 mb">
        {{ $t('InterviewBooked.you_ve_booked_an') }}
      </div>
      <div v-if="timeslot">
        <event-day :startDate="timeslot.startDate" :durationInMinutes="timeslot.durationInMinutes" />
        <div class="can_join mb-16 mx-0">
          {{ $t('InterviewBooked.we_will_wait_you_on_time') }}<br />
          {{ $t('InterviewBooked.you_can_join_us') }}
        </div>
        <ui-link href="https://us02web.zoom.us/j/8864608622">https://us02web.zoom.us/j/8864608622</ui-link>
      </div>
    </div>
    <div class="fixed_footer container">
      <ui-button class="mb-16" @click="reschedule">{{ $t('InterviewBooked.reschedule_an_interview') }}</ui-button>
      <ui-link class="link" @click="cancel">{{ $t('InterviewBooked.cancel_an_interview') }}</ui-link>
    </div>
    <SelectInterviewDateSlider
      v-if="timeslot"
      :isShow="isSelecting"
      :interviewId="interview.id"
      :callback="modalClose"
      @slotChange="setTimeslot"
    />
  </div>
</template>

<script>
import { paramsForServer } from 'feathers-hooks-common';
import SelectInterviewDateSlider from './SelectInterviewDateSlider';
import UploadCV from './UploadCV';
import UiButton from '../ui/UiButton';
import UiLink from '../ui/UiLink';
import EventDay from '../review/EventDay';
import mapFields from '../../utils/mapFields';

export default {
  name: 'InterviewBooked',
  components: {
    EventDay,
    UiLink,
    UiButton,
    SelectInterviewDateSlider
  },
  props: {
    modal: {
      type: Object
    }
  },
  computed: {
    ...mapFields({
      userId: 'auth.user.id'
    })
  },
  data() {
    return {
      interview: null,
      timeslot: null,
      isSelecting: false
    };
  },
  methods: {
    async reschedule() {
      this.modalOpen();
    },
    setTimeslot(timeslot) {
      this.timeslot = timeslot;
    },
    async cancel() {
      try {
        const { targetStatus } = await this.$store.dispatch('interviews/remove', [
          this.interview.id,
          paramsForServer({ disableParanoid: true })
        ]);
        this.$emit('status', targetStatus.status);
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },
    modalClose() {
      this.isSelecting = false;
    },
    modalOpen() {
      this.isSelecting = true;
    }
  },
  async created() {
    const flag = window.localStorage.getItem('UploadCV');
    if (!flag) {
        this.modal.component = UploadCV;
        this.modal.title = '';
        this.modal.componentData = {};
        this.modal.visible = true;
        this.modal.callback = () => {
          this.modal.visible = false;
        };
    }

    const { data: interviews } = await this.$store.dispatch('interviews/find', {
      query: { userId: this.userId }
    });

    if (interviews.length) {
      this.interview = interviews[interviews.length - 1];
      this.timeslot = this.interview.timeslot;
    }
  }
};
</script>

<style scoped>
.can_join {
  color: #7d7d7d;
}
</style>
