<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/payment_2x.png"><br>
      <div class="title3 mb-8">{{$t('PaymentConfirming.we_are_confirming_your')}}</div>
      <div class="text3 mb">
        {{$t('PaymentConfirming.thanks_we_ll_get_back_to')}}<br>
        we’ll check out your payment. <br>{{$t('PaymentConfirming.usually_it_may_take_up_to')}}<br>
        so stay tuned!
      </div>

      <div class="contacts">
        <div class="mb-8">
          {{$t('PaymentConfirming.if_you_have_any_problems')}} <br>
          contact support using Whatsapp
        </div>
        <div>
          <img class="whatsapp" src="@/assets/images/whatsapp_2x.png"> 
          <ui-link href="https://wa.me/+37499076823" target="_blank">+374 99076823</ui-link>
        </div>
      </div>

    </div>

    <!-- <div class="footer"> -->
    <div class="fixed_footer container">
      <ui-link class="link" @click="wrongFile">{{$t('PaymentConfirming.i_ve_added_a_wrong_file')}}</ui-link>
    </div>
  </div>
</template>

<script>
  import UiLink from '../ui/UiLink';

  export default {
    name: 'PaymentConfirming',
    components: { UiLink },
    methods: {
      wrongFile() {
        this.$emit('status', 'payment_by_wire_transfer')
      }
    }
  }
</script>

<style scoped>
  .contacts {
    width: 70%;
  }
  .whatsapp {
    height: 24px;
    vertical-align: middle;
  }
</style>
