<template>
  <transition name="fade" :duration="1000">
    <div v-if="isVisible" class="modal-container">
      <div class="modal-overlay"></div>
      <div class="modal-wrapper">
        <div class="modal-flex" @mousedown.self="close('by_fade')">
          <component
            :is="config.component"
            :title="config.title"
            :componentData="config.componentData"
            @close="close($event)"
          ></component>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'UiModalView',
    props: {
      config: {
        type: Object,
        required: true,
      }
    },
    data() {
      console.log('#modal data');
      return {};
    },
    methods: {
      close(data) {
        if (this.config.callback) {
          this.config.callback(data);
          this.config.callback = false;
        }
        this.config.visible = false;
      }
    },
    computed: {
      isVisible() {
        console.log('#modal is visible');
        return this.config.visible;
      }
    },
    watch: {
      // хак, для того чтобы нельзя было скролить body при открытом модальном окне
      // eslint-disable-next-line no-unused-vars
      isVisible(newValue, oldValue) {
        const body = document.getElementsByTagName('body')[0];
        if (newValue) {
          body.classList.add('modal-open');
        } else {
          body.classList.remove('modal-open');
        }
      }
    },
    config: {
      visible: false,
      component: {},
      componentData: {},
    }
  }
</script>

<style>
  body.modal-open {
    overflow: hidden;
  }
</style>

<style scoped lang="scss">

  .modal-container {
    z-index: 2000;
  }

  .modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    z-index: 1000;
  }

  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    outline: 0 !important;
    -webkit-backface-visibility: hidden;

    z-index: 1001;
  }

  .modal-flex {

    min-height: 100%;

    padding: 20px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    /*border:1px solid red;*/
  }

  .fade-enter-active {
    .modal-overlay {
      transition: opacity 0.3s;
      z-index: 2000;
    }
    .modal-wrapper {
      transition: opacity 0.2s;
      z-index: 2000;
    }
  }

  .fade-leave-active {
    .modal-overlay {
      transition: opacity 0.2s;
      z-index: 2000;
    }
    .modal-wrapper {
      transition: opacity 0.3s;
      z-index: 2000;
    }
  }

  .fade-enter, .fade-leave-to {
    .modal-overlay {
      opacity: 0;
    }
    .modal-wrapper {
      opacity: 0;
    }
  }

</style>
