<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/pay_finish_2x.png"><br>
      <div class="title3 mb-8">{{$t('Introduction.congratulations')}}</div>
      <div class="text3 mb">
        {{$t('Introduction.now_your_dream_in_germany')}}
      </div>
      <event-day class="mb-32"></event-day>
    </div>
    <!-- <div class="onboarding_footer"> -->
    <div class="fixed_footer container">
      <ui-button>{{$t('Introduction.go_to_my_personal_account')}}</ui-button>
    </div>
  </div>
</template>

<script>
    import UiButton from '../ui/UiButton';
    import EventDay from '../review/EventDay';

    export default {
        name: 'Introduction',
      components: { EventDay, UiButton }
    }
</script>

<style scoped>

</style>
