<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/interview_2x.png" /><br />
      <div class="title3 mb-8">{{$t('InterviewMissed.booking_an_interview')}}</div>
      <div class="text3 mb" v-if="isInterviewLoaded">
        {{$t('InterviewMissed.you_ve_missed_your')}}
        <strong>{{`${month}, ${day}`}} {{startHour | alwaysTwoDigits}}:{{startMintutes | alwaysTwoDigits}} – {{endHour
            | alwaysTwoDigits}}:{{endMintutes | alwaysTwoDigits}}
        </strong
        ><br />
        {{$t('InterviewMissed.please_reschedule_an')}}<br />
        on another date
      </div>
    </div>
    <!-- <div class="onboarding_footer"> -->
    <div class="fixed_footer container">
      <a href="https://fitmost.simplybook.it/v2/#book" target="_blank">
        <ui-button>{{$t('InterviewMissed.reschedule_an_interview')}}</ui-button>
      </a>
    </div>
  </div>
</template>

<script>
import UiButton from '../ui/UiButton';
import mapFields from '../../utils/mapFields';

export default {
  name: 'InterviewMissed',
  components: { UiButton },
  data() {
    return {
      isInterviewLoaded: false
    };
  },
  computed: {
    ...mapFields({
      email: 'auth.user.email'
    })
  },
  async created() {
    const { data } = await this.$store.dispatch('interviews/find', {
      query: { email: this.email }
    });

    if (data.length) {
      const { startDate, endDate } = data[data.length - 1];
      const start = new Date(startDate);
      const end = new Date(endDate);
      this.month = start.toLocaleDateString('en-US', { month: 'short' });
      this.day = start.toLocaleDateString('en-US', { day: 'numeric' });
      this.dayName = start.toLocaleDateString('en-US', { weekday: 'short' });
      this.startHour = start.getHours();
      this.startMintutes = start.getMinutes();
      this.endHour = end.getHours();
      this.endMintutes = end.getMinutes();

      this.isInterviewLoaded = true;
    }
  }
};
</script>

<style scoped></style>
