<template>
  <div class="onboarding_content">
    <img class="onboarding_icon_sm mb" src="@/assets/images/sorry_2x.png"><br>
    <div class="title3 mb-8">{{$t('InterviewRejected.we_are_sorry')}}</div>
    <div class="text3 mb">
      {{$t('InterviewRejected.unfortunately_at_the_moment')}}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InterviewRejected'
  }
</script>

<style scoped>

</style>
