<template>
  <div>
    <transition name="fade">
      <div v-if="isShow" class="fade" @mousedown.self="close('by_fade')"></div>
    </transition>
    <div class="share_slider_wrap" :class="{ active: isShow }" @mousedown.self="close('by_fade')">
      <div class="share_slider container">
        <div class="title mb">
          <div class="text title1">{{ $t('SelectInterviewDateSlider.select_a_date') }}</div>
          <div class="close" @click="close"><img src="@/assets/images/close.svg" /></div>
        </div>

        <div v-if="timeslotsExtended.length" class="slots_wrapper">
          <!-- <div class="share_item" v-for="timeslot in timeslotsExtended" :key="timeslot.id" @click="book(timeslot)">
            <div class="text">
              <div class="text">{{ timeslot.label }}</div>
              <UiButton class="interview_btn">{{ timeslot.label }}</UiButton>
            </div>
          </div> -->

          <ui-radio-group :options="timeslotsExtended" :vertical="true" @change="book"></ui-radio-group>
        </div>
        <div v-else>{{ $t('SelectInterviewDateSlider.no_avaliable_timeslots') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { mapGetters } from 'vuex';
import { paramsForServer } from 'feathers-hooks-common';
import UiRadioGroup from '../ui/UiRadioGroup';
import mapFields from '../../utils/mapFields';
import UiButton from '../ui/UiButton';

dayjs.extend(weekday);

export default {
  name: 'SelectInterviewDateSlider',
  components: { UiButton, UiRadioGroup },
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    interviewId: {
      type: String
    },
    callback: {
      type: Function
    }
  },

  data() {
    return {
      timeslots: []
    };
  },

  computed: {
    ...mapFields({
      userId: 'auth.user.id',
      studentId: 'auth.user.student.id',
      email: 'auth.user.email'
    }),
    ...mapGetters('timeslots', {
      getSlotById: 'get'
    }),
    timeslotsExtended() {
      // return this.timeslots.map((ts) => ({
      //   ...ts,
      //   label: `${dayjs(ts.startDate).format('dddd')},
      //   ${dayjs(ts.startDate).format('MMM D')} at
      //   ${dayjs(ts.startDate).format('ha - ')}
      //     ${dayjs(ts.startDate)
      //       .add(ts.durationInMinutes, 'minute')
      //       .format('ha')} (KSA)`
      // }));

      return this.timeslots
        .filter((ts) => !ts.deletedAt)
        .map((ts) => ({
          value: ts.id,
          label: `${dayjs(ts.startDate).format('dddd')},
        ${dayjs(ts.startDate).format('MMMM D')} at
        ${dayjs(ts.startDate).format('ha')}`
        }));
    }
  },
  methods: {
    close(data) {
      this.callback(data);
    },
    async addNewStatus() {
      try {
        global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'book_interview');
        await this.$store.dispatch('student-statuses/create', {
          status: 'interview_booked',
          comment: '',
          studentId: this.studentId
        });
      } catch (error) {
        this.$notify.parseFeathersErrors(error);
      }
    },
    async book(event) {
      const timeslotId = event.target.value;
      try {
        if (this.interviewId) {
          await this.$store.dispatch('interviews/patch', [
            this.interviewId,
            {
              status: 'rescheduled',
              userId: this.userId,
              timeslotId
            }
          ]);
          global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'reschedule_interview');
          this.$emit('slotChange', await this.getSlotById(timeslotId));
        } else {
          const result = await this.$store.dispatch('interviews/create', {
            status: 'confirmed',
            userId: this.userId,
            timeslotId
          });
          console.log(result);
          await this.addNewStatus();
        }
        this.close();
      } catch (err) {
        this.$notify.parseFeathersErrors(err);
      }
    }
  },
  watch: {
    // хак, для того чтобы нельзя было скролить body при открытом модальном окне
    // eslint-disable-next-line no-unused-vars
    isShow(newValue, oldValue) {
      const body = document.getElementsByTagName('body')[0];
      if (newValue) {
        body.classList.add('modal-open');
      } else {
        body.classList.remove('modal-open');
      }
    }
  },
  async mounted() {
    try {
      const { data } = await this.$store.dispatch(
        'timeslots/find',
        paramsForServer({
          query: {
            $limit: 30,
            $sort: {
              startDate: 1
            },
            startDate: {
              $gt: new Date().getTime()
            }
          }
        })
      );
      this.timeslots = data;
      console.log(data);
    } catch (err) {
      this.$notify.parseFeathersErrors(err);
    }
  }
};
</script>

<style>
body.modal-open {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.fade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.share_slider_wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0px;
  overflow: hidden;
  transition: height 0.5s ease;
  z-index: 1;
}

.share_slider {
  height: 100%;
  background: rgb(255, 255, 255);
  min-height: 100px;
  border-radius: 8px 8px 0px 0px;
  padding-top: 24px;
}

.share_slider_wrap.active {
  height: 280px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.title {
  display: flex;

  .text {
    flex-grow: 1;
    text-align: left;
  }
}

.slots_wrapper {
  max-height: 85%;
  overflow: scroll;
}

.share_item {
  display: flex;
  border-radius: 4px;
  padding: 2px 0px;
  margin-bottom: 8px;
  cursor: pointer;
  background: #f5f8ff;

  .text {
    flex-grow: 1;
    font-size: 16px;
  }

  .interview_btn {
    background: none;
    font-size: 14px;
    text-align: left;
    padding-left: 26px;
    margin-bottom: 6px;
  }

  //.icon {
  //  width: 24px;
  //  height: 24px;
  //  background-repeat: no-repeat;
  //  background-size: contain;
  //}
  //
  //.facebook {
  //  background-image: url("~@/assets/images/share/facebook.png");
  //}
  //
  //.whatsapp {
  //  background-image: url("~@/assets/images/share/whatsapp.png");
  //}
  //
  //.mail {
  //  background-image: url("~@/assets/images/share/mail.png");
  //}
  //
  //.copy {
  //  background-image: url("~@/assets/images/share/copy.png");
  //}
}
</style>
