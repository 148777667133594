<template>
  <div class="steps noselect">
    <div v-for="step in computedSteps" :key="step.key" :class="step.classes">{{step.title}}</div>
    <div class="line"></div>
  </div>
</template>

<script>
  export default {
    name: 'ReviewSteps',
    props: {
      steps: {
        type: Array,
        required: true,
      }
    },
    computed: {
      computedSteps() {
        return this.steps.slice(0, 3).map((step, index) => ({
          key: `${step}${index}`,
          classes: {
            step: true,
            started: step === 'started',
            done: step === 'done',
            error: step === 'error',
          },
          title: step !== 'done' && step !== 'error' ? index + 1 : ''
        }));
      }
    }
  };
</script>

<style scoped>
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 60%;

    margin-left: auto;
    margin-right: auto;
    height: 24px;
  }

  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    padding-top: 2px;
    color: #ffffff;
    z-index: 1;
    border: 3px solid white;
    background-color: #D9D9D9;
  }

  .started {
    background-color: #FFBC42;
  }

  .done {
    background-color: #59CD90;
    background-image: url("~@/assets/images/step_done.svg");
    background-repeat: no-repeat;
    background-position: 7px 8px;
  }

  .error {
    background-color: #FF5964;
    background-image: url("~@/assets/images/step_error.svg");
    background-repeat: no-repeat;
    background-position: 8px 8px;
  }

  .line {
    position: absolute;
    height: 1px;
    top: 50%;
    left: 5px;
    right: 5px;
    background: #D9D9D9;
  }
</style>
