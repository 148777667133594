<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/interview_2x.png" /><br />
      <div class="title3 mb-8">{{ $t('InterviewBooking.booking_an_interview') }}</div>
      <div class="text3 mb">
        {{ $t('InterviewBooking.thank_you_for_being_one_of') }}
      </div>
    </div>
    <!-- <div class="onboarding_footer"> -->
    <div class="fixed_footer container">
      <a>
        <ui-button @click="select">{{ $t('InterviewBooking.book_an_interview') }}</ui-button>
      </a>
    </div>
    <SelectInterviewDateSlider :isShow="isSelecting" :callback="modalClose" />
  </div>
</template>

<script>
// TODO: !! delete <a> tag
import UiButton from '../ui/UiButton';
import InterviewCongratulations from './InterviewCongratulations';
import SelectInterviewDateSlider from './SelectInterviewDateSlider';

export default {
  name: 'InterviewBooking',
  components: {
    SelectInterviewDateSlider,
    UiButton
  },
  props: {
    modal: {
      type: Object
    }
  },
  data() {
    return {
      isSelecting: false
    };
  },
  mounted() {
    global.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'visit_interview_booking');
    const flag = window.localStorage.getItem('InterviewCongratulations');
    if (!flag) {
      window.localStorage.setItem('InterviewCongratulations', '1');
      setTimeout(() => {
        this.modal.component = InterviewCongratulations;
        this.modal.title = '';
        this.modal.data = {};
        this.modal.visible = true;
        this.modal.callback = () => {
          this.modal.visible = false;
        };
      }, 0);
    }
  },
  methods: {
    modalClose() {
      this.isSelecting = false;
    },
    select() {
      this.isSelecting = true;
    }
  }
};
</script>

<style scoped></style>
