<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content mb-fixed-footer">
      <img class="onboarding_icon" src="@/assets/images/payment_2x.png" /><br />
      <div class="title3 mb-8">{{ $t('PaymentByWireTransfer.payment_is_required') }}</div>
      <div class="text3 mb-48">
        {{ $t('PaymentByWireTransfer.to_proceed_you_also_can_pay') }}
      </div>
      <div class="slider">
        <div class="slider-title mb-8" @click="toggle">
          <div class="grow">{{ $t('PaymentByWireTransfer.how_can_i_make_a_wire') }}</div>
          <div class="arrow">
            <img v-if="isShow" src="@/assets/images/arrow-down.svg" />
            <img v-else src="@/assets/images/arrow-right.svg" />
          </div>
        </div>
        <SlideUpDown :active="isShow">
          <ui-form-item label="Select a currency in which you want to pay" prop="currency" class="mb-16 fixed_radio">
            <ui-radio-group :options="currencies" v-model="values.currency"></ui-radio-group>
          </ui-form-item>
          <p class="text mb-8">1. {{ $t('PaymentByWireTransfer.go_to_your_local') }}:</p>
          <div class="bank-data mb-16">
            {{ $t('PaymentByWireTransfer.bank') }}
            <div class="value mb-4">
              <span v-if="isEuro">{{ $t('PaymentByWireTransfer.ameriabank_pay_in_euro') }}</span>
              <span v-else>{{ $t('PaymentByWireTransfer.ameriabank_pay_in_dollar') }}</span>
            </div>
            {{ $t('PaymentByWireTransfer.bank_account_number') }}
            <div class="value mb-4">
              <span v-if="isEuro">{{ $t('PaymentByWireTransfer.number_euro') }}</span>
              <span v-else>{{ $t('PaymentByWireTransfer.number_dollar') }}</span>
            </div>
            {{ $t('PaymentByWireTransfer.bank_account_name') }}
            <div class="value mb-4">{{ $t('PaymentByWireTransfer.germanlanguage_ph_llc') }}</div>
            {{ $t('PaymentByWireTransfer.swift_code') }}
            <div class="value mb-4">{{ $t('PaymentByWireTransfer.armiam') }}</div>
            {{ $t('PaymentByWireTransfer.branch') }}<br />
            <div class="value mb-12">2 Vazgen Sargsyan St, Yerevan 0010</div>
            <div class="bold">{{ $t('PaymentByWireTransfer.please_write_a_language') }}</div>
          </div>
          <p class="text mb-12">
            2. {{ $t('PaymentByWireTransfer.get_the_photo') }}
            <ui-link href="https://wa.me/+37499076823" target="_blank">
              <span class="font-14">+374 99076823</span>
            </ui-link>
          </p>
        </SlideUpDown>
      </div>
    </div>
    <div class="fixed_footer container">
      <div class="mt-48">
        <ui-link class="link" @click="backToCard">{{ $t('PaymentByWireTransfer.pay_with_a_card') }}</ui-link>
      </div>
    </div>
  </div>
</template>

<script>
import UiLink from '../ui/UiLink';
import SlideUpDown from '../SlideUpDown';
import UiFormItem from '../ui/UiFormItem';
import UiRadioGroup from '../ui/UiRadioGroup';

export default {
  name: 'PaymentByWireTransfer',
  components: {
    SlideUpDown,
    UiLink,
    UiFormItem,
    UiRadioGroup
  },
  props: {
    modal: {
      type: Object
    }
  },
  data() {
    return {
      isShow: false,
      currencies: [
        { value: 'EUR', label: 'EUR' },
        { value: 'USD', label: 'USD' }
      ],
      values: {
        currency: 'EUR'
      }
    };
  },
  computed: {
    isEuro() {
      return this.values.currency === 'EUR';
    }
  },
  methods: {
    toggle() {
      this.isShow = !this.isShow;
    },
    backToCard() {
      // h
      this.$emit('status', 'payment_by_card');
    }
  }
};
</script>

<style scoped lang="scss">
.slider {
  width: 100%;
  text-align: left;
}
.slider-title {
  font-size: 16px;
  color: #383838;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 48px;
  .grow {
    flex-grow: 1;
  }
}
.text {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0em;
}
.bank-data {
  color: #7d7d7d;
  font-size: 14px;
  line-height: 22px;

  .value {
    color: #383838;
  }

  .bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #383838;
  }
}
</style>

<style lang="scss">
.fixed_radio {
  .ant-form-item-label {
    height: auto !important;
  }
}
</style>
