<template>
  <div class="onboarding_wrap">
    <div class="onboarding_content">
      <img class="onboarding_icon mb" src="@/assets/images/interview_2x.png"><br>
      <div class="title3 mb-8">Employer is reviewing your application</div>
      <div class="text3 mb">
        Thanks for attending employer interview.<br>
        We will contact you on Wednesday
      </div>

      <div class="contacts">
        <div class="mb-8">
          {{$t('PaymentConfirming.if_you_have_any_problems')}} <br>
          contact our support using Whatsapp
        </div>
        <div>
          <img class="whatsapp" src="@/assets/images/whatsapp_2x.png"> 
          <ui-link href="https://wa.me/+37499076823" target="_blank">+374 99076823</ui-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import UiLink from '../ui/UiLink.vue'

export default {
  components: { UiLink },
  name: 'InterviewAttended'
}
</script>

<style scoped>
.whatsapp {
  height: 24px;
  vertical-align: middle;
}
</style>
