<template>
  <div class="file-uploader">
    <input v-show="false" ref="files" type="file" :multiple="multiple" :accept="accept" @change="handleFilesUpload" />
    <div v-if="files.length">
      <div v-for="(file, index) in files" :key="index">
        <div v-if="file" class="file">
          <a :href="`${urls[index]}?token=${token}`" target="_blank" class="name">{{ file.name }}</a>
          <div class="delete" @click="deleteFile(index)"></div>
        </div>
      </div>
    </div>
    <div
      v-else
      ref="dropzone"
      class="uploader_area flex-center"
      :class="{ highlight }"
      @dragover.prevent="handleDragOver"
      @drop.prevent="handleDrop"
      @click="addFiles"
    >
      <span class="help">
        {{message}}
      </span>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-syntax,no-await-in-loop */
import 'dragster';
import Axios from 'axios';

export default {
  name: 'FileUploader',
  props: {
    value: {
      require: false,
      type: Object
    },
    url: {
      require: true,
      type: String
    },
    path: {
      require: true,
      type: String
    },
    multiple: {
      default: false,
      type: Boolean
    },
    accept: {
      default: '',
      type: String
    },
    fileSize: {
      require: false,
      type: Number
    },
    fileName: {
      require: true,
      type: String
    },
    message: {
      require: true,
      type: String
    }
  },
  data() {
    return {
      token: localStorage['feathers-jwt'],
      dragster: {},
      highlight: false,
      files: [],
      urls: []
    };
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.files = [newValue];
        this.urls = [`${this.url}/${newValue.key}`];
      } else {
        this.files = [];
      }
    }
  },
  mounted() {
    // setup file
    if (this.value && this.value.id) {
      this.files = [this.value];
      this.urls = [`${this.url}/${this.value.key}`];
    }

    this.dragster = new window.Dragster(this.$refs.dropzone);
    this.$refs.dropzone.addEventListener('dragster:enter', this.handleDragEnter);
    this.$refs.dropzone.addEventListener('dragster:leave', this.handleDragLeave);
  },
  beforeDestroy() {
    this.dragster.removeListeners();
  },
  methods: {
    handleDragEnter() {
      this.highlight = true;
    },
    handleDragLeave() {
      this.highlight = false;
    },
    handleDragOver() {},
    handleDrop(e) {
      this.files = e.dataTransfer.files;
    },
    deleteFile(e) {
      this.$emit('delete', e);
      this.cleanFiles();
    },
    cleanFiles() {
      this.files = [];
      this.urls = [];
    },
    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload() {
      this.files = [...this.$refs.files.files];
      if (this.files.length) {
        if (this.fileSize) {
          const filesValid = this.files.reduce((result, file) => result && file.size <= this.fileSize, true);
          if (!filesValid) {
            this.cleanFiles();
            this.$emit('error', `File(s) too big, max size ${Math.floor(this.fileSize / 1024 / 1024)}mb`);
            return;
          }
        }

        this.submitFiles();
      }
    },
    uploadCustomFiles(files) {
      this.files = [...files];
      if (this.files.length) {
        this.submitFiles();
      }
    },
    async submitFiles() {
      const responses = [];
      const progressList = {};

      for (const file of this.files) {
        progressList[file.name] = {
          size: file.size,
          loaded: 0
        };
      }

      this.$emit('start');
      this.$emit('progress', 0);

      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i];
        try {
          const formData = new FormData();
          formData.append(this.fileName, file);

          const { data } = await Axios.post(`${this.url}/${this.path}`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage['feathers-jwt']}`,
              'Content-Type': 'multipart/form-data',
              Accept: 'application/json'
            },
            onUploadProgress: (progressEvent) => {
              progressList[file.name].loaded = progressEvent.loaded;

              const totalSize = Object.values(progressList).reduce((a, b) => a + b.size, 0);
              const totalLoaded = Object.values(progressList).reduce((a, b) => a + b.loaded, 0);
              const progress = Math.round((totalLoaded / totalSize) * 100);

              this.$emit('progress', progress);
            }
          });

          this.urls.push(`${this.url}/${data.key}`);
          responses.push(data);
        } catch (e) {
          console.log('upload error', e);
          this.$emit('error', e);
          // reset input's files or input state will not change
          this.$refs.files.value = '';
          this.cleanFiles();
        }
      }

      if (responses.length) {
        this.$emit('success', responses);
      }

      this.$emit('progress', 100);
      setTimeout(() => {
        this.$emit('progress', 0);
      }, 500)
    }
  }
};
</script>

<style scoped lang="scss">
.file-uploader {

  .file {
    display: flex;
    padding: 2px;
    text-align: left;
  }
  .name {
    color: #383838;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 24px;
    background-image: url('~@/assets/images/file.svg');
    background-repeat: no-repeat;
    background-position: 0px 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  .delete {
    margin-left: 24px;
    width: 20px;

    background-image: url('~@/assets/images/delete_file.svg');
    background-repeat: no-repeat;
    background-position: 0px 50%;
    opacity: 0.7;
    transition: all 0.5s ease;
    cursor: pointer;
    margin-right: -2px;
  }
  .delete:hover {
    opacity: 1;
  }
}

.uploader_area {
  height: 96px;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  border-radius: 4px;

  cursor: pointer;

  .help {
    padding-top: 30px;
    background-image: url('~@/assets/images/file_upload_help.svg');
    background-repeat: no-repeat;
    background-position: 50% 0px;
    font-size: 14px;
    color: #bfbfbf;
  }
}

.highlight {
  border: 1px dashed #3e90d9;
}
</style>
