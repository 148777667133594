<template>
  <div :class="['ui_radio_button', { 'vertical': this.vertical }]">
    <a-radio-group v-bind="{ ...$attrs, ...$props }" v-on="$listeners"></a-radio-group>
  </div>
</template>

<script>
  export default {
    name: 'UiRadioGroup',
    props: {
      options: {
        type: Array,
        required: true,
      },
      vertical: {
        type: Boolean,
        required: false
      }
    },
  };
</script>

<style lang="scss">
  .ui_radio_button {
    .ant-radio-group {
      display: flex;
    }
    .ant-radio-wrapper {
      flex: 1 1 100px;
      border: 1px solid #D9D9D9;
      box-sizing: border-box;
      border-radius: 4px;

      height: 48px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .ant-radio-wrapper:last-child {
      margin-right: 0;
    }
    .ant-radio-wrapper-checked {
      border: 1px solid #258FFB;
    }
  }

  .ui_radio_button.vertical {
    .ant-radio-group {
      display: flex;
      flex-direction: column;
    }
    .ant-radio-wrapper {
      flex: none;
      margin-right: 0px;
      margin-bottom: 8px;
    }
  }
</style>
