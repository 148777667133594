<template>
  <div class="popup">
    <div class="flex-grow flex-center">
      <div>
        <img class="icon mb" src="@/assets/images/congratulations.svg"><br>
        <div class="title3 mb-8">{{$t('PaymentCongratulations.congratulations')}}</div>
        <div class="text2">{{$t('PaymentCongratulations.you_were_granted')}}</div>
      </div>
    </div>
    <div class="close" @click="close"><img src="@/assets/images/close.svg"></div>
  </div>
</template>

<script>

  export default {
    name: 'PaymentCongratulations',
    methods: {
      close() {
        this.$emit('close');
      }
    }
  }
</script>

<style scoped>
  .popup {
    width: 328px;
    height: 404px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 32px 24px;

    display: flex;
    flex-direction: column;
    position: relative;
  }

  .close {
    position: absolute;
    top: 19px;
    right: 27px;
    cursor: pointer;
  }
</style>
